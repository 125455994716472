import moment from "moment"
import { useState, useEffect } from "react"
import numeral from "numeral"
import { jwtDecode } from "jwt-decode"
import cryptoJs from "crypto-js"
import Cookies from "universal-cookie"

const cookies = new Cookies()

export function TimeDifference(startTime, endTime) {
  const sMoment = moment(
    startTime?.length <= 13 ? parseInt(startTime) : startTime
  ).format("MM/DD/YYYY")
  const sp = moment(
    startTime?.length <= 13 ? parseInt(startTime) : startTime
  ).format("HH:mm")
  const start_time = new Date(
    moment(`${sMoment} ${sp}`).format("MM/DD/yyyy HH:mm")
  )
  const eMoment = moment(
    endTime?.length <= 13 ? parseInt(endTime) : endTime
  ).format("MM/DD/YYYY")
  const ep = moment(endTime?.length <= 13 ? parseInt(endTime) : endTime).format(
    "HH:mm"
  )
  const end_time = new Date(
    moment(`${eMoment} ${ep}`).format("MM/DD/yyyy HH:mm")
  )

  const time_df = moment
    .utc(
      moment(end_time, "DD/MM/YYYY HH:mm:ss").diff(
        moment(start_time, "DD/MM/YYYY HH:mm:ss")
      )
    )
    .format("HH:mm:ss")
  const splittedTime = time_df.split(":")
  const date1 = new Date(start_time)
  const date2 = new Date(end_time)
  const diffInMs = Math.abs(date2 - date1)
  const days = diffInMs / (1000 * 60 * 60 * 24)
  const splitDaysStr = days.toString().split(".")
  const splitDays = parseInt(splitDaysStr[0])

  if (time_df) {
    if (splitDays <= 0) {
      return `${splittedTime[0]} HOURS ${splittedTime[1]} MINS`
    } else {
      return `${splitDays} DAY ${splittedTime[0]} HOURS ${splittedTime[1]} MINS`
    }
  }
}

export function toTimestamp(date) {
  let strDate = new Date(date)
  let timestamp = new Date(strDate).getTime() / 1000
  return parseInt(timestamp)
}
export const ChartMonthlyColor = () => {
  return [
    "#ffd237",
    "#ffb121",
    "#fe892f",
    "#ffd237",
    "#146391",
    "#6945a2",
    "#9b254f",
    "#ab2356",
    "#6dd4ad",
    "#64d0db",
    "#35b1e4",
    "#1d77b1",
  ]
}
export function getFullDate(date) {
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  return `${month}/${day}/${year}`
}

export function getCurrencySymbols(code) {
  let symbol = ""
  switch (code) {
    case "USD":
      symbol = "$"
      break
    case "SGD":
      symbol = "S$"
      break
    default:
      symbol = "$"
  }
  return symbol
}


export function getFormattedName(name) {
  let formattedName = ""
  switch (name) {
    case "FLEET_MANAGER":
      formattedName = "Fleet Manager"
      break
    case "OPERATOR":
      formattedName = "Operator"
      break
    case "ADMIN":
      formattedName = "Admin"
      break
    case "OTHERS":
      formattedName = "Others"
      break
    default:
      formattedName = name
  }
  return formattedName
}

export function dateFormatter(seconds) {
  let formattedDate = moment(seconds * 1000).format("DD MMM YY")
  return formattedDate
}

export function dateTimeFormatter(date) {
  let formattedDateTime = moment(date).format("DD-MM-YYYY HH:mm:ss")
  return formattedDateTime
}

export function getOptionsFromDocumentType(option) {
  let optionToShow = ""
  switch (option) {
    case "MILEAGE":
      optionToShow = "Update Miles"
      break
    case "GAS":
      optionToShow = "Update Gas"
      break
    case "EXPENSE":
      optionToShow = "Update Expense"
      break
    default:
      optionToShow = ""
  }
  return optionToShow
}
export function usaFormatNumber(phone) {
  if (phone) {
    if (phone?.length === 10) {
      return (
        "(" +
        phone.slice(0, 3) +
        ")" +
        phone.slice(3, 6) +
        "-" +
        phone.slice(6, 10)
      )
    } else if (phone?.length > 10) {
      return (
        "(" +
        phone.slice(2, 5) +
        ")" +
        phone.slice(5, 8) +
        "-" +
        phone.slice(8, 12)
      )
    } else if (phone?.length === 0) {
      return "Null"
    } else {
      return phone
    }
  } else {
    return "NA"
  }
}
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  } else if (phoneNumberString) {
    return phoneNumberString
  } else {
    return null
  }
}

export const setLocalStorageByKey = (key, data) => {
  let updatedData = JSON.stringify(data);
  localStorage.setItem(key, updatedData)
}

export const getLoginLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const localLoginDataString = localStorage.getItem('sp_data')
    return JSON.parse(localLoginDataString);
  }
  return undefined;
}

export const getLoginClientOptions = () => {
  if (typeof window !== 'undefined') {
    const clientOptionsString = localStorage.getItem('clientOptions')
    return JSON.parse(clientOptionsString);
  }
  return undefined;
}

export const isAdminRole = () => {
  if (typeof window !== 'undefined') {
    const localLoginDataString = localStorage.getItem('sp_data')
    const userData = JSON.parse(localLoginDataString);
    return userData != null && userData.roles.some(e => ['Admin', 'NS2P-Admin'].includes(e))
  }
  return false;
}

export const removeLoginLocalStorage = () => {
  localStorage.removeItem('sp_data')
  localStorage.removeItem('toggle_data')
  localStorage.removeItem('sessionId')
  localStorage.removeItem('clientOptions')
  localStorage.removeItem("apiVersion");
}

export function dateDiff(timestamp) {
  var finalDiff = ""
  let now = moment(new Date()).format("DD/MM/YYYY HH:mm:ss") //todays date
  let end1 = moment(timestamp) // another date
  let end = moment(new Date(end1)).format("DD/MM/YYYY HH:mm:ss")
  let ms = moment(end, "DD/MM/YYYY HH:mm:ss").diff(
    moment(now, "DD/MM/YYYY HH:mm:ss")
  )
  let d = moment.duration(ms)
  let r = {
    months: Math.floor(d.asMonths()),
    weeks: Math.floor(d.asWeeks()) - 4 * Math.floor(d.asMonths()),
    days: Math.floor(d.asDays()) - 7 * Math.floor(d.asWeeks()),
    hours: Math.floor(d.asHours()) - 24 * Math.floor(d.asDays()),
    minutes: (Math.floor(d.asMinutes()) / 60).toFixed(0),
  }
  Object.keys(r).forEach(function (key) {
    if (r[key] && r[key] > 0) {
      finalDiff += ` ${r[key]} ${key}`
    }
  })
  return finalDiff
}

export function getStatusColour(status) {
  let color = ""
  switch (status) {
    case "Red":
      color = "#ff0000"
      break
    case "Green":
      color = "#32CD32"
      break
    case "Amber":
      color = "#FFA500"
      break
    default:
      color = "#32CD32"
      break
  }
  return color
}

export function mapDateToDay(date) {
  let finalDay = date
  let today = new Date()
  let day = today.getDate()
  let month = today.getMonth() + 1
  let year = today.getFullYear()

  let newDate = new Date(date)
  let dayForDate = newDate.getDate()
  let monthForDate = newDate.getMonth() + 1
  let yearForDate = newDate.getFullYear()

  if (year === yearForDate) {
    if (month === monthForDate) {
      if (day === dayForDate) {
        finalDay = "Today"
      } else if (day + 1 === dayForDate) {
        finalDay = "Tomorrow"
      } else if (day - 1 === dayForDate) {
        finalDay = "yesterday"
      } else {
        finalDay = dateFormatter(finalDay.getTime() / 1000)
      }
    }
  }
  return finalDay
}

export function NumToStr(originalNumber, decimalLength) {
  // if (decimalLength == 0) {
  //     return  numeral(originalNumber).format('0,0');
  // }
  let currentVal = numeral(originalNumber).format("0,0.00")
  return currentVal
}

const getWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined"
  const width = hasWindow ? window.innerWidth : null
  const height = hasWindow ? window.innerHeight : null
  return {
    width,
    height,
  }
}

export function dragAndDropRowToBeUpdate(e, dataSource, targetRow) {
  const sourceRowKey = e.dataTransfer.getData('application/json');

  const sourceRow = dataSource.find((row) => row.id === parseInt(sourceRowKey));
  const targetRowIdx = dataSource.findIndex((row) => row.id === targetRow.id);

  let data;
  if (sourceRow && targetRow) {
    const priorityDiff = targetRowIdx - dataSource.indexOf(sourceRow);

    data = dataSource.map((row) => {
      if (row.id === sourceRow.id) {
        const updateData = {
          ...row,
          priorityIndex: row.priorityIndex + priorityDiff
        };
        return updateData
      }
    })
  }
  return Object.assign({}, ...data)
}

export function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined"
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [hasWindow])

  return windowDimensions
}

export function assendingSort(val) {
  return val.sort((a, b) =>
    a.displayValue.toUpperCase() > b.displayValue.toUpperCase()
      ? 1
      : b.displayValue.toUpperCase() > a.displayValue.toUpperCase()
        ? -1
        : 0
  )
}

export function TextCapitalize(value) {
  if (value && value !== "NA") {
    const str = value.toString()
    if (str.toLowerCase().split("-").length > 1) {
      return str
        .toLowerCase()
        .split("-")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join("-")
    } else {
      return str
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    }
  } else if ((value && value === "NA") || value === "") {
    return "NA"
  } else {
    return value
  }
}
// export function TextUpperCase(value) {
//   return <span className="text-uppercase">{value}</span>
// }
// export function TextLowerCase(value) {
//   return <span className="text-lowercase">{value}</span>
// }
// export function getLocation(lat, long) {
//     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
//     Geocode.setApiKey("AIzaSyAz4zjDqzBIfW11TjZWNaTPYDCw1HxRzHg");

//     // set response language. Defaults to english.
//     Geocode.setLanguage("en");

//     // set response region. Its optional.
//     // A Geocoding request with region=es (Spain) will return the Spanish city.
//     Geocode.setRegion("es");

//     // Enable or disable logs. Its optional.
//     Geocode.enableDebug();

//     // Get address from latidude & longitude.
//     Geocode.fromLatLng(lat, long).then(
//       response => {
//         address = response.results[0].formatted_address;
//         return address;
//     },
//     error => {
//
//     }
//     );
// }

export const StringFormatter = string => {
  let arr = string.split(" ")
  arr = arr.map(
    item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
  )
  return arr.join(" ")
}

export const extractUrl = query => {
  if (typeof window !== 'undefined') {
    const location = window.location.href
    let url = new URL(location)
    let params = new URLSearchParams(url.search)
    let mode = params.get(query)
    return mode
  } else {
    return ""
  }

}

export const clearLocalStorageData = () => {
  localStorage.clear();
}


export const getQueryVariable = (variable) => {
  if (typeof window !== 'undefined') {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  } else {
    return false
  }

}

export const objectToUrl = (obj) => {
  let keys = Object.keys(obj)
  let url = "?"
  for (let i = 0; i < keys.length; i++) {
    if (i !== 0) {
      url = url + "&"
    }
    if (Array.isArray(obj[keys[i]])) {
      if (obj[keys[i]].length > 0) {
        for (let k = 0; k < obj[keys[i]].length; k++) {
          switch (keys[i]) {
            case "filter":
              if (i !== 0 && k !== 0) {
                url = url + "&"
              }
              if (i === 0 && k !== 0) {
                url = url + "&"
              }
              if (obj[keys[i]][k].operator !== undefined) {
                url =
                  url +
                  `filter.${obj[keys[i]][k].key}=${obj[keys[i]][k].operator}:${obj[keys[i]][k].value
                  }`
              } else {
                url =
                  url + `filter.${obj[keys[i]][k].key}=${obj[keys[i]][k].value}`
              }
              break
            default:
              if (i !== 0 && k !== 0) {
                url = url + "&"
              }
              if (i === 0 && k !== 0) {
                url = url + "&"
              }
              url = url + `${keys[i]}=${obj[keys[i]][k]}`
              break
          }
        }
      }
    } else {
      url = url + `${keys[i]}=${obj[keys[i]]}`
    }
  }
  return url
}

export const urlToObject = (url) => {
  const obj = {};
  const params = new URLSearchParams(url);
  for (const [key, value] of params.entries()) {
    if (key.includes('.')) {
      const [mainKey, subKey] = key.split('.');
      if (!obj[mainKey]) {
        obj[mainKey] = [];
      }
      obj[mainKey].push({ key: subKey, value });
    } else {
      obj[key] = value;
    }
  }
  return obj;
};

export const getTenantToggle = (value) => {
  if (typeof window !== 'undefined') {
    const toggle = localStorage.getItem('toggle_data')
    if (toggle === null) {
      return false
    }
    const tenantConfig = JSON.parse(toggle).tenantConfig;
    if (tenantConfig === undefined) {
      return false
    }
    return tenantConfig?.filter(x => x.name === value).length && tenantConfig.filter(e => e.name === value)[0].isActive
  }
}

export const getFeatureToggle = (value) => {
  if (typeof window !== 'undefined') {
    const toggle = localStorage.getItem('toggle_data')
    if (toggle === null) {
      return false
    }
    const featureToggle = JSON.parse(toggle).featureToggle;
    if (featureToggle === undefined) {
      return false
    }
    return featureToggle?.filter(x => x.featureName === value).length && featureToggle.filter(e => e.featureName === value)[0].isActive
  }
}

export const getOnboardingInvite = () => {
  if (typeof window !== 'undefined') {
    const toggle = localStorage.getItem('toggle_data')
    if (toggle === null) {
      return false
    }
    const onboardingInvite = JSON.parse(toggle).onboardingInvite;
    if (onboardingInvite === undefined) {
      return false
    }
    return onboardingInvite;
  }
}

export const dateFormat = (date) => {
  return moment(date).format("DD MMM YYYY hh:mm A");
}

export const redirectLogin = () => {
  if (typeof window !== "undefined") {
    const dashboard = getTenantToggle("Dashboard")
    const manageInvoice = getFeatureToggle("MANAGE-INVOICE")
    const onBoarding = getFeatureToggle("ONBOARDING")
    if (dashboard && manageInvoice) {
      return "/dashboard"
    } else if (onBoarding) {
      return "/inbox"
    } else if (manageInvoice && !dashboard) {
      return "/trackInvoice"
    } else {
      return "/profile"
    }
  }
}

export const pascalCaseToSentence = (pascalCaseString) => {
  // Replace capital letters with spaces and lowercase versions of the letters
  const sentence = pascalCaseString.replace(/([A-Z])/g, ' $1').trim();
  // Convert the first character to uppercase to start the sentence
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export const getHeightOfTheScreeniframe = () => {
  const hasWindow = typeof window !== "undefined"
  let href = hasWindow ? window.location.href : null;
  let heightOfFrame
  if (href != null) {
    if (href.includes('details')) {
      heightOfFrame = hasWindow ? window.innerHeight - 245 : null
    } else {
      heightOfFrame = hasWindow ? window.innerHeight - 145 : null
    }
  } else {
    heightOfFrame = hasWindow ? window.innerHeight - 110 : null
  }

  return heightOfFrame
}

export function encryptRequest(data, token) {
  const decryptedToken = jwtDecode(token);
  const upData = JSON.stringify(data);
  const encryptedData = cryptoJs.AES.encrypt(upData, decryptedToken.reqId).toString();
  return encryptedData;
}

export function decryptResponse(data, token) {
  let decryptedToken = jwtDecode(token)
  let bytes = cryptoJs.AES.decrypt(data, decryptedToken.reqId)
  let originalText = bytes.toString(cryptoJs.enc.Utf8)
  if (originalText.length > 0) {
    return JSON.parse(originalText)
  } else {
    return data;
  }
}

export const convertToDecimal = (num) => {
  const n = Number.parseFloat(num);
  if (!num || isNaN(n) || n < 0) return 0;
  return Number(n.toFixed(2));
}

export function getUserId(token) {
  let decryptedToken = jwtDecode(token)
  return decryptedToken || undefined
}

export const customFilterOption = (inputValue, option) => {
  return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
};

export const getLoggedInUserEmailId = () => {
  if (typeof window !== 'undefined') {
    const localLoginDataString = localStorage.getItem('sp_data')
    return JSON.parse(localLoginDataString)?.['emailId'];
  }
  return undefined;
}

export const getLoggedInUserId = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  try {
    const localLoginDataString = localStorage.getItem('sp_data');
    if (!localLoginDataString) return undefined;
    const parsedData = JSON.parse(localLoginDataString);
    return parsedData?.id ?? undefined;
  } catch (error) {
    console.error('Error parsing localStorage data:', error);
    return undefined;
  }
};

export function returnRequestBodyForMicroflowObject(data, requestBodyMapJson) {
  let reqBdy = {}
  let token = cookies.get("token");

  for (let r = 0; r < requestBodyMapJson.length; r++) {
    if (requestBodyMapJson[r].responseKey && data && data[requestBodyMapJson[r].responseKey] !== undefined && data[requestBodyMapJson[r].responseKey] !== null) {
      reqBdy[requestBodyMapJson[r].updateKey] = data[requestBodyMapJson[r].responseKey]
    } else if (requestBodyMapJson[r].updateKey == 'NULL') {
      reqBdy[requestBodyMapJson[r].updateKey] = null
    } else if (requestBodyMapJson[r].updateKey == 'UNDEFINED') {
      reqBdy[requestBodyMapJson[r].updateKey] = undefined
    } else if (requestBodyMapJson[r].updateKey == 'access_token') {
      reqBdy[requestBodyMapJson[r].updateKey] = token
    } else {
      reqBdy[requestBodyMapJson[r].updateKey] = requestBodyMapJson[r].responseKey
    }
  }
  return reqBdy;
}

export default function removeExtraValues(obj) {
  for (const key in obj) {
    if (obj[key] === key) {
      obj[key] = null;
    }
  }
  return obj;
}

export const ensureTrailingSlash = (string) => string && !string.endsWith('/') ? `${string}/` : string;

export const removeTrailingSlash = (string) => string && string.endsWith('/') ? string.slice(0, -1) : string;

export const getFileBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split('base64,')[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};
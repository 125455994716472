import Cookies from "universal-cookie";
import { redirectLogin } from "./src/utils/helper";
import { getLocalStorageByKey } from "./src/utils/formRender";
import { navigate } from "gatsby";

const cookies = new Cookies();

export { default as wrapRootElement } from "./ReduxWrapper";

const adminPaths = [
    "/setting/group-management/",
    "/setting/users/"
];

const ns2pAdminPaths = [
    "/admin/users/",
    "/admin/on-boarding-setup/",
    "/admin/config-invoice-form/",
    "/line-items-config/",
    "/support-contact/",
    "/third-party-integration/",
    "/feature-toggle/",
    "/tenant-configuration/",
    "/instance-config/",
    "/system-logs/",
    "/admin/email-config/",
    "/admin/email-template/",
    "/email-logs/",
    "/sms-config/",
    "/admin/api-logs/",
    "/upload-logo/"
];

export const onRouteUpdate = ({ location }) => {
    try {
        if (typeof window === "undefined") return;

        const token = cookies.get("token");
        if (token) {
            const userData = getLocalStorageByKey("sp_data");
            const userRoles = userData?.roles || [];
            const currentPath = location.pathname;

            if (
                (adminPaths.includes(currentPath) && !userRoles.includes("Admin")) ||
                (ns2pAdminPaths.includes(currentPath) && !userRoles.includes("NS2P-Admin"))
            ) {
                navigate(redirectLogin());
            }
        }
    } catch (e) {
        console.error("Error in gatsby-browser.js", e);
    }
};

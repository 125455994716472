import moment from "moment"
import { cloneDeep } from "lodash"
import _ from "lodash"

export function getLocalStorageByKey(key) {
  if (typeof window !== "undefined") {
    let data = localStorage.getItem(key)
    if (data != "" && data != undefined && data != null) {
      let updatedData = JSON.parse(data)
      return updatedData
    } else {
      return null
    }
  } else {
    return null
  }
}

export function checkIFFileIsValid(fileType, fileName) {
  let validFiles = [
    "image/jpeg",
    "application/pdf",
    "message/rfc822",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/msword",
    "application/zip",
    "text/plain",
    "image/png",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ]
  let rg1 = /^[^\\/:\*\?"@%=+!#&(,)${};<>\|]+$/ // forbidden characters \ / : * ? " < > |
  let rg2 = /^\./ // cannot start with dot (.)
  let rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i // forbidden file names
  let fileNameValid =
    rg1.test(fileName) && !rg2.test(fileName) && !rg3.test(fileName)
  let validFile = false
  if (validFile == false && fileType == "") {
    let nameSplit = fileName.split(".")
    if (nameSplit.length > 0 && nameSplit[nameSplit.length - 1] == "msg") {
      validFile = true
    }
  } else {
    validFile = validFiles.includes(fileType)
  }

  if (validFile == true) {
    if (fileNameValid == true) {
      return true
    } else {
      return 'File name should not contain special characters ^ ? < > |  / " : * $ { } , ; % # @ & !'
    }
  } else {
    return "Not a valid file"
  }
}

export function getQueryObject(rawObj, data, paginationData, searchText) {
  let queryObjectUpdated = {}
  let keys = Object.keys(rawObj)
  for (let i = 0; i < keys.length; i++) {
    let keyName = keys[i]

    switch (keyName) {
      case "tableName":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["tableName"] = rawObj["tableName"]
        }
        break
      case "distinctColumnName":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["distinctColumnName"] =
            rawObj["distinctColumnName"]
        }
        break
      case "searchBy":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["searchBy"] = rawObj["searchBy"]
        }
        break
      case "search":
        if (
          rawObj[keyName] !== undefined &&
          rawObj[keyName] !== null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] !== ""
        ) {
          if (rawObj[keyName] == "SEARCHTEXT") {
            queryObjectUpdated["search"] = searchText
          } else if (
            data[rawObj[keyName]] !== undefined &&
            data[rawObj[keyName]] !== null
          ) {
            queryObjectUpdated["search"] = data[rawObj[keyName]]
          }
        }
        break
      case "sortBy":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          let sortArr = rawObj[keyName].split(":")
          let sort = ""
          if (sortArr.length > 0) {
            if (sortArr[0] == "SORT_COLUMN_NAME") {
              if (
                paginationData != undefined &&
                paginationData.sortField != undefined
              ) {
                sort = sort + paginationData.sortField
              }
            } else {
              sort = sort + sortArr[0]
            }
            if (sortArr[1] == "ORDER") {
              if (
                paginationData != undefined &&
                paginationData.sortOrder != undefined
              ) {
                let order =
                  paginationData.sortOrder == "ascend" ? "ASC" : "DESC"
                let colon = (typeof (sort) != "undefined") ? (":") : ''
                sort = sort + colon + order
              }
            } else {
              sort = sort + ":" + sortArr[1]
            }
            queryObjectUpdated["sortBy"] = sort
          }
        }
        break
      case "limit":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          if (
            paginationData != undefined &&
            Object.keys(paginationData).length !== 0
          ) {
            if (paginationData.pageSize !== undefined) {
              queryObjectUpdated["limit"] = paginationData.pageSize;
            } else {
              queryObjectUpdated["limit"] = 9999;
            }
          } else {
            queryObjectUpdated["limit"] = rawObj[keyName];
          }
        }
        break;

      case "page":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          if (
            paginationData != undefined &&
            paginationData.current != undefined
          ) {
            queryObjectUpdated["page"] = paginationData.current
          } else {
            queryObjectUpdated["page"] = 1
          }
        }
        break
      case "filter":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          let updatedFilter = []
          for (let j = 0; j < rawObj["filter"].length; j++) {
            let filter = rawObj["filter"][j]
            let upFilter = {}
            if (filter.key) {
              upFilter["key"] = filter.key
            }
            if (filter.value && filter.value == "SEARCHTEXT") {
              upFilter["value"] = searchText
            } else if (
              filter.value != undefined &&
              data[filter.value] != undefined
            ) {
              upFilter["value"] = data[filter.value]
            } else {
              let index = filter.value.indexOf("|");
              if (index != -1) {
                let valArr = filter.value.split('|');
                let value = ''
                for (let v = 0; v < valArr.length; v++) {
                  if (v != 0) {
                    value = value + ','
                  }
                  if (valArr[v] != undefined &&
                    data[valArr[v]] != undefined) {
                    value = value + data[valArr[v]]
                  } else {
                    if (valArr[v].includes('DATE_')) {
                      //Accepts DATE_CURRENT, DATE_MONTHS_2
                      let splitMonths = valArr[v].split('_');
                      if (splitMonths.length > 0 && splitMonths[1] == 'CURRENT') {
                        let currentDate = moment().format('YYYY-MM-DD');
                        value = value + currentDate;
                      } else if (splitMonths.length > 0 && splitMonths[1] == 'MONTHS') {
                        let date = moment().subtract(parseInt(splitMonths[2]), 'months').format('YYYY-MM-DD');
                        value = value + date;
                      }

                    } else {
                      value = value + valArr[v]
                    }
                  }
                }
              } else {
                let _index = -1;
                let val = filter.value;
                if (filter.value && filter.value != undefined) {
                  _index = filter.value.indexOf(".");
                }
                if (_index != -1) {
                  val = _.get(data, filter.value)
                }
                upFilter["value"] = val
              }
            }
            if (filter.operator) {
              upFilter["operator"] = filter.operator
            }
            updatedFilter.push(upFilter)
          }
          queryObjectUpdated["filter"] = updatedFilter
        }
        break
    }
  }
  return queryObjectUpdated
}
export function checkRule(rule, invoiceData) {
  let value = rule.value
  if (value && value === "true") {
    value = true
  } else if (value && value === "false") {
    value = false
  }
  if (
    invoiceData &&
    invoiceData[rule.value] &&
    invoiceData[rule.value] != undefined
  ) {
    value = invoiceData[rule.value]
  }
  switch (rule.cond) {
    case "eq":
      if (invoiceData && invoiceData[rule.refKey] == value) {
        return false
      } else {
        return true
      }
      break
    case "lt":
      if (invoiceData && invoiceData[rule.refKey] < value) {
        return false
      } else {
        return true
      }
      break
    case "gt":
      if (invoiceData && invoiceData[rule.refKey] > value) {
        return false
      } else {
        return true
      }
      break
    case "lte":
      if (invoiceData && invoiceData[rule.refKey] <= value) {
        return false
      } else {
        return true
      }
      break
    case "gte":
      if (invoiceData && invoiceData[rule.refKey] >= value) {
        return false
      } else {
        return true
      }
      break
    case "neq":
      if (invoiceData && invoiceData[rule.refKey] != value) {
        return false
      } else {
        return true
      }
      break
    case "empty":
      if (
        invoiceData &&
        invoiceData[rule.refKey] != undefined &&
        invoiceData[rule.refKey] != null &&
        invoiceData[rule.refKey] != ""
      ) {
        return false
      } else {
        return true
      }
      break
    case "eq-custom":
      if (this.state[rule.refKey] && this.state[rule.refKey] == value) {
        return false
      } else {
        return true
      }
      break
    case "date-diff":
      let startDate
      let endDate
      if (invoiceData && invoiceData[rule.refStartDateKey]) {
        startDate = moment(invoiceData[rule.refStartDateKey])
      }
      if (invoiceData && invoiceData[rule.refEndDateKey]) {
        endDate = moment(invoiceData[rule.refEndDateKey])
      }
      if (startDate && endDate) {
        let dateDiff = endDate.diff(startDate, "days")
        value = parseInt(value) || 0
        switch (rule.difference) {
          case "gt":
            if (dateDiff > value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "lt":
            if (dateDiff < value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "eq":
            if (dateDiff == value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "gte":
            if (dateDiff >= value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "lte":
            if (dateDiff <= value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
        }
      } else {
        return true
      }

      break

    case "RO":
    case "displayRoles":
      if (rule.displayRoles && rule.displayRoles.length == 0) {
        return false
      } else if (rule.displayRoles && rule.displayRoles.length > 0) {
        if(invoiceData.hasOwnProperty('assignmentDetails')){
          let filteredRolesPanel = invoiceData.assignmentDetails.filter(ele =>
            rule.displayRoles.includes(ele.stageName)
          );
          return filteredRolesPanel.length > 0 ? false : true;
        } else if (invoiceData.hasOwnProperty('stageName')) {
          return rule.displayRoles.includes(invoiceData.stageName) ? false : true;
        }
        
        else {
          return false
        }
      } else {
        return true
      }
      break
      case "clientRoles":
        const clientDetails = JSON.parse(localStorage.getItem("clientDetails"));
        if (clientDetails) {
          if (rule.clientRoles) {
            const result = !rule.clientRoles.includes(clientDetails.id.toString());
            return result;
          }
          return true;
        }
        
        return true;

    case "table-column-gt":
      if (invoiceData != undefined) {
        let filterData = invoiceData.filter(
          ele => parseFloat(ele[rule.refKey]) > rule.value
        )
        if (filterData.length != invoiceData.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }

    default:
      return false
  }
}

export function ruleValidate(item, invoiceData, returnStateVariables) {
  let enabled = false
  if (item && item.enableRule && item.enableRule == true) {
    let accRules = item.rule
    let ruleType = item.ruleType ? item.ruleType : "OR"
    let ruleResults = []
    for (let i = 0; i < accRules.length; i++) {
      if (accRules[i].ruleType != undefined) {
        let accChildRuleType = accRules[i].ruleType
        let accChildRuleResults = []
        for (let j = 0; j < accRules[i].rules.length; j++) {
          let upInvData = invoiceData
          if (
            accRules[i].rules[j] &&
            accRules[i].rules[j].referenceSource != undefined &&
            returnStateVariables != undefined
          ) {
            upInvData = returnStateVariables(
              accRules[i].rules[j].referenceSource
            )
          }
          let accChildRuleResp = checkRule(accRules[i].rules[j], upInvData)
          accChildRuleResults.push(accChildRuleResp)
        }
        if (accChildRuleType == "OR") {
          let accFilterdRes = accChildRuleResults.filter(ele => ele === false)
          if (accFilterdRes.length > 0) {
            ruleResults.push(false)
          } else {
            ruleResults.push(true)
          }
        } else if (accChildRuleType == "AND") {
          let accFilterdRes = accChildRuleResults.filter(ele => ele === true)
          if (accFilterdRes.length > 0) {
            ruleResults.push(true)
          } else {
            ruleResults.push(false)
          }
        }
      } else {
        let ruleResp = checkRule(accRules[i], invoiceData)
        ruleResults.push(ruleResp)
      }
    }

    if (ruleType == "OR") {
      let filterdRes = ruleResults.filter(ele => ele === false)
      if (filterdRes.length > 0) {
        enabled = false
      } else {
        enabled = true
      }
    } else if (ruleType == "AND") {
      let filterdRes = ruleResults.filter(ele => ele === true)
      if (filterdRes.length > 0) {
        enabled = true
      } else {
        enabled = false
      }
    }
  } else {
    enabled = false
  }
  if (item && item.displayRoles && item.displayRoles.length > 0) {
    if (
      invoiceData != undefined &&
      invoiceData.assignmentDetails != undefined
    ) {
      let filteredRolesPanel = invoiceData.assignmentDetails.filter(ele =>
        item.displayRoles.includes(ele.stageName)
      )
      if (filteredRolesPanel && filteredRolesPanel.length > 0) {
        enabled = false
      } else {
        enabled = true
      }
    } else {
      enabled = false
    }
  }
  return enabled
}

export const objectToUrl = obj => {
  let keys = Object.keys(obj)
  let url = "?"
  for (let i = 0; i < keys.length; i++) {
    if (i !== 0) {
      url = url + "&"
    }
    if (Array.isArray(obj[keys[i]])) {
      if (obj[keys[i]].length > 0) {
        for (let k = 0; k < obj[keys[i]].length; k++) {
          switch (keys[i]) {
            case "filter":
              if (i !== 0 && k !== 0) {
                url = url + "&"
              }
              if (i === 0 && k !== 0) {
                url = url + "&"
              }
              if (obj[keys[i]][k].operator !== undefined) {
                url =
                  url +
                  `filter.${obj[keys[i]][k].key}=${obj[keys[i]][k].operator}:${obj[keys[i]][k].value
                  }`
              } else {
                url =
                  url + `filter.${obj[keys[i]][k].key}=${obj[keys[i]][k].value}`
              }
              break
            default:
              if (i !== 0 && k !== 0) {
                url = url + "&"
              }
              if (i === 0 && k !== 0) {
                url = url + "&"
              }
              url = url + `${keys[i]}=${obj[keys[i]][k]}`
              break
          }
        }
      }
    } else {
      url = url + `${keys[i]}=${obj[keys[i]]}`
    }
  }
  return url
}

export const getTenantToggle = value => {
  if (typeof window !== "undefined") {
    const toggle = localStorage.getItem("toggle_data")
    if (toggle === null) {
      return false
    }
    const tenantConfig = JSON.parse(toggle).tenantConfig
    if (tenantConfig === undefined) {
      return false
    }
    return (
      tenantConfig?.filter(x => x.name === value).length &&
      tenantConfig.filter(e => e.name === value)[0].isActive
    )
  }
}

export const getFeatureToggle = value => {
  if (typeof window !== "undefined") {
    const toggle = localStorage.getItem("toggle_data")
    if (toggle === null) {
      return false
    }
    const featureToggle = JSON.parse(toggle).featureToggle
    if (featureToggle === undefined) {
      return false
    }
    return (
      featureToggle?.filter(x => x.featureName === value).length &&
      featureToggle.filter(e => e.featureName === value)[0].isActive
    )
  }
}

export const dateFormat = date => {
  return moment(date).format("DD MMM YYYY hh:mm A")
}

export function getRowStyles(item) {
  if (item && item != undefined) {
    const styles = {
      height: item.height,
      width: item.width,
      marginTop: item.marginTop,
      marginBottom: item.marginBottom ? item.marginBottom:'10px',
      marginLeft: item.marginLeft,
      marginRight: item.marginRight,
      paddingTop: item.paddingTop,
      paddingBottom: item.paddingBottom,
      paddingLeft: item.paddingLeft,
      paddingRight: item.paddingRight,
    }
    return styles
  } else {
    const defaultstyles = {
      height: "auto",
      minHeight: "35px",
    }
    return defaultstyles
  }
}

export function getTextStyles(item) {
  if (item && item != undefined) {
    const styles = {
      fontSize: item.fontSize,
      color: item.color,
      fontWeight: item.fontWeight,
    }
    return styles
  }
}

export function omitStyles(obj, keys) {
  if (!keys.length) return obj
  const key = keys.pop()
  const parts = key.split(".")
  if (parts.length > 1) {
    const { [parts[0]]: todo, ...rest } = obj
    return {
      ...omitStyles(rest, keys),
      [parts[0]]: omitStyles(todo, [parts[1]]),
    }
  }
  const { [key]: omitted, ...rest } = obj
  return omitStyles(rest, keys)
}

export function checkIfTaskIsAssignedToUser(invoiceData) {
  if (invoiceData != undefined && invoiceData.assignmentDetails != undefined) {
    let assignmentDetails = cloneDeep(invoiceData.assignmentDetails)
    let userId = getLocalStorageByKey("data")?.id
    let filteredUser = assignmentDetails.filter(ele => ele.userId == userId)
    if (filteredUser.length > 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export function validateSaveInvoiceHeader(dispJson, invoiceData, stateData) {
  let validateFields = []
  dispJson.map((item, index) => {
    if (item.type == "Accordion") {
      if (item.displayRoles && item.displayRoles.length == 0) {
        item["hide"] = false
      } else if (item.displayRoles && item.displayRoles.length > 0) {
        let filteredRolesPanel = invoiceData?.assignmentDetails.filter(ele =>
          item.displayRoles.includes(ele.stageName)
        )
        if (filteredRolesPanel && filteredRolesPanel.length > 0) {
          item["hide"] = false
        } else {
          item["hide"] = true
        }
      } else {
        item["hide"] = false
      }
      if (item.enableRule && item.enableRule == true) {
        let accRules = item.rule
        let ruleType = item.ruleType ? item.ruleType : "OR"
        let ruleResults = []
        for (let i = 0; i < accRules.length; i++) {
          if (accRules[i].ruleType != undefined) {
            let accChildRuleType = accRules[i].ruleType
            let accChildRuleResults = []
            for (let j = 0; j < accRules[i].rules.length; j++) {
              let updatedInvoiceData = {}
              if (
                accRules[i].rules[j] &&
                accRules[i].rules[j].referenceSource != undefined &&
                accRules[i].rules[j].referenceSource != "" &&
                accRules[i].rules[j].referenceSource != null
              ) {
                updatedInvoiceData =
                  stateData[accRules[i].rules[j].referenceSource]
              } else {
                updatedInvoiceData = invoiceData
              }
              let accChildRuleResp = checkRule(
                accRules[i].rules[j],
                updatedInvoiceData
              )
              accChildRuleResults.push(accChildRuleResp)
            }
            if (accChildRuleType == "OR") {
              let accFilterdRes = accChildRuleResults.filter(
                ele => ele === false
              )
              if (accFilterdRes.length > 0) {
                ruleResults.push(false)
              } else {
                ruleResults.push(true)
              }
            } else if (accChildRuleType == "AND") {
              let accFilterdRes = accChildRuleResults.filter(
                ele => ele === true
              )
              if (accFilterdRes.length > 0) {
                ruleResults.push(true)
              } else {
                ruleResults.push(false)
              }
            }
          } else {
            let updatedInvoiceData = {}
            if (
              accRules[i] &&
              accRules[i].referenceSource != undefined &&
              accRules[i].referenceSource != "" &&
              accRules[i].referenceSource != null
            ) {
              updatedInvoiceData = stateData[accRules[i].referenceSource]
            } else {
              updatedInvoiceData = invoiceData
            }
            let ruleResp = checkRule(accRules[i], updatedInvoiceData)
            ruleResults.push(ruleResp)
          }
        }

        if (ruleType == "OR") {
          let filterdRes = ruleResults.filter(ele => ele === false)
          if (filterdRes.length > 0) {
            item["hide"] = false
          } else {
            item["hide"] = true
          }
        } else if (ruleType == "AND") {
          let filterdRes = ruleResults.filter(ele => ele === true)
          if (filterdRes.length > 0) {
            item["hide"] = true
          } else {
            item["hide"] = false
          }
        }
      }

      if (item["hide"] == false) {
        item.pannelFields.map(field => {
          if (
            field["type"] == "table-default-edit" ||
            field["type"] == "table"
          ) {
            field["required"] = false
          } else {
            if (field.key == "advanceToBeSettledHeader") {
            }
            if (field.enableRule && field.enableRule == true) {
              let rules = field.rule
              let ruleType = field.ruleType ? field.ruleType : "OR"
              let ruleResults = []
              for (let i = 0; i < rules.length; i++) {
                if (rules[i].ruleType != undefined) {
                  let childRuleType = rules[i].ruleType
                  let childRuleResults = []
                  for (let j = 0; j < rules[i].rules.length; j++) {
                    let updatedInvoiceData = {}
                    if (
                      rules[i].rules[j] &&
                      rules[i].rules[j].referenceSource != undefined &&
                      rules[i].rules[j].referenceSource != "" &&
                      rules[i].rules[j].referenceSource != null
                    ) {
                      updatedInvoiceData =
                        stateData[rules[i].rules[j].referenceSource]
                    } else {
                      updatedInvoiceData = invoiceData
                    }
                    let accChildRuleResp = checkRule(
                      rules[i].rules[j],
                      updatedInvoiceData
                    )
                    if (rules[i].rules[j]?.cond == "RO") {
                      if (accChildRuleResp == false) {
                        field["type"] = "view"
                        field["required"] = false
                      }
                    } else {
                      childRuleResults.push(accChildRuleResp)
                    }
                  }
                  if (childRuleResults.length > 0) {
                    if (childRuleType == "OR") {
                      let childFilterdRes = childRuleResults.filter(
                        ele => ele === false
                      )
                      if (childFilterdRes.length > 0) {
                        ruleResults.push(false)
                      } else {
                        ruleResults.push(true)
                      }
                    } else if (childRuleType == "AND") {
                      let childFilterdRes = childRuleResults.filter(
                        ele => ele === true
                      )
                      if (childFilterdRes.length > 0) {
                        ruleResults.push(true)
                      } else {
                        ruleResults.push(false)
                      }
                    }
                  }
                } else {
                  let updatedInvoiceData = {}
                  if (
                    rules[i] &&
                    rules[i].referenceSource != undefined &&
                    rules[i].referenceSource != "" &&
                    rules[i].referenceSource != null
                  ) {
                    updatedInvoiceData = stateData[rules[i].referenceSource]
                  } else {
                    updatedInvoiceData = invoiceData
                  }
                  let ruleResp = checkRule(rules[i], updatedInvoiceData)
                  if (rules[i].cond == "RO") {
                    if (ruleResp == false) {
                      field["type"] = "view"
                      field["required"] = false
                    }
                  } else {
                    ruleResults.push(ruleResp)
                  }
                }
              }
              if (ruleResults.length > 0) {
                if (ruleType == "OR") {
                  let filterdRes = ruleResults.filter(ele => ele === false)
                  if (filterdRes.length > 0) {
                    field["hide"] = false
                    if (field["hide"] == false && field["required"] == false) {
                      field["required"] = false
                    } else {
                      field["required"] = true
                    }
                  } else {
                    field["hide"] = true
                    field["required"] = false
                  }
                } else if (ruleType == "AND") {
                  let filterdRes = ruleResults.filter(ele => ele === true)
                  if (filterdRes.length > 0) {
                    field["hide"] = true
                    field["required"] = false
                  } else {
                    field["hide"] = false
                    if (field["hide"] == false && field["required"] == false) {
                      field["required"] = false
                    } else {
                      field["required"] = true
                    }
                  }
                }
              }
            }

            if (field.enableReadOnlyRule && field.enableReadOnlyRule == true) {
              switch (field.readOnlyRule.cond) {
                case "RO":
                  let filteredRolesReadOnly =
                    invoiceData?.assignmentDetails.filter(ele =>
                      field.readOnlyRule.displayRoles.includes(ele.stageName)
                    )
                  if (
                    filteredRolesReadOnly &&
                    filteredRolesReadOnly.length > 0
                  ) {
                    field["type"] = field.readOnlyRule.fieldType
                    if (
                      field["type"] != "view" &&
                      field.required == true &&
                      field["hide"] == false
                    ) {
                      // validateFields.push(field.key)
                      field["required"] = true
                    } else {
                      field["required"] = false
                    }
                  }
              }
            }
            if (
              field.enableMandatoryRule &&
              field.enableMandatoryRule == true
            ) {
              switch (field.mandatoryRule.cond) {
                case "mandatory-roles":
                  let filteredRolesReadOnly =
                    this.props.invoiceHeaderData?.assignmentDetails.filter(
                      ele =>
                        field.mandatoryRule.displayRoles.includes(ele.stageName)
                    )
                  if (
                    filteredRolesReadOnly &&
                    filteredRolesReadOnly.length > 0
                  ) {
                    // field['required'] = field.mandatoryRule.required;
                    if (
                      field.mandatoryRule.required == true &&
                      field["hide"] == false
                    ) {
                      // validateFields.push(field.key)
                      field["required"] = true
                    } else {
                      field["required"] = false
                    }
                  }
              }
            }
            if (field.displayRoles && field.displayRoles.length == 0) {
              field["hide"] = false
            } else if (field.displayRoles && field.displayRoles.length > 0) {
              let filteredRoles = invoiceData?.assignmentDetails.filter(ele =>
                field.displayRoles.includes(ele.stageName)
              )
              if (filteredRoles && filteredRoles.length > 0) {
                field["hide"] = false
                field["required"] = false
                if (field["hide"] == false && field.required == true) {
                  // validateFields.push(field.key);
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              } else {
                field["hide"] = true
                if (field["hide"] == false && field.required == true) {
                  // validateFields.push(field.key)
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              }
            }
          }

          if (field.regex && field.regex != "") {
            let reg = field.regex;
            if (field.regex == 'other') {
              reg = field?.otherRegex;
            }
            if (
              invoiceData[field.key] &&
              invoiceData[field.key] != undefined &&
              invoiceData[field.key] != null &&
              invoiceData[field.key] != ""
            ) {
              let regex = new RegExp(reg, "g")
              let result = regex.test(invoiceData[field.key])
              if (result == false) {

                validateFields.push(
                  field.errorMessage
                )
              }
            }
          }

          if (field["required"] == true) {
            if (
              invoiceData[field.key] == undefined ||
              invoiceData[field.key] === null
            ) {
              validateFields.push(
                "Please fill the mandatory fields like " + field.label
              )
            } else {
              let value = invoiceData[field.key];
              value = value.toString().trim();
              if (value == undefined ||
                value == null || value == "") {
                validateFields.push(
                  "Please fill the mandatory fields like " + field.label
                )
              }
              if (isNaN(invoiceData[field.key])) {
                value = value.trim();
                if (value == undefined ||
                  value == null || value == "") {
                  validateFields.push(
                    "Please fill the mandatory fields like " + field.label
                  )
                }
              }
              if (field['type'] == 'checkbox') {
                if (invoiceData[field.key] === false) {
                  validateFields.push(
                    "Please fill the mandatory fields like " + field.label
                  )
                }
              }
            }
          }
        })
      }
    } else {
      let field = item
      if (field["type"] == "table-default-edit" || field["type"] == "table") {
        field["required"] = false
      } else {
        if (field.enableRule && field.enableRule == true) {
          let rules = field.rule
          let ruleType = field.ruleType ? field.ruleType : "OR"
          let ruleResults = []
          for (let i = 0; i < rules.length; i++) {
            if (rules[i].ruleType != undefined) {
              let childRuleType = rules[i].ruleType
              let childRuleResults = []
              for (let j = 0; j < rules[i].rules.length; j++) {
                let updatedInvoiceData = {}
                if (
                  rules[i].rules[j] &&
                  rules[i].rules[j].referenceSource != undefined &&
                  rules[i].rules[j].referenceSource != "" &&
                  rules[i].rules[j].referenceSource != null
                ) {
                  updatedInvoiceData =
                    stateData[rules[i].rules[j].referenceSource]
                } else {
                  updatedInvoiceData = invoiceData
                }
                let accChildRuleResp = checkRule(
                  rules[i].rules[j],
                  updatedInvoiceData
                )
                if (rules[i].rules[j]?.cond == "RO") {
                  if (accChildRuleResp == false) {
                    field["type"] = "view"
                    field["required"] = false
                  }
                } else {
                  childRuleResults.push(accChildRuleResp)
                }
              }
              if (childRuleResults.length > 0) {
                if (childRuleType == "OR") {
                  let childFilterdRes = childRuleResults.filter(
                    ele => ele === false
                  )
                  if (childFilterdRes.length > 0) {
                    ruleResults.push(false)
                  } else {
                    ruleResults.push(true)
                  }
                } else if (childRuleType == "AND") {
                  let childFilterdRes = childRuleResults.filter(
                    ele => ele === true
                  )
                  if (childFilterdRes.length > 0) {
                    ruleResults.push(true)
                  } else {
                    ruleResults.push(false)
                  }
                }
              }
            } else {
              let updatedInvoiceData = {}
              if (
                rules[i] &&
                rules[i].referenceSource != undefined &&
                rules[i].referenceSource != "" &&
                rules[i].referenceSource != null
              ) {
                updatedInvoiceData = stateData[rules[i].referenceSource]
              } else {
                updatedInvoiceData = invoiceData
              }
              let ruleResp = checkRule(rules[i], updatedInvoiceData)
              if (rules[i].cond == "RO") {
                if (ruleResp == false) {
                  field["type"] = "view"
                  field["required"] = false
                }
              } else {
                ruleResults.push(ruleResp)
              }
            }
          }
          if (ruleResults.length > 0) {
            if (ruleType == "OR") {
              let filterdRes = ruleResults.filter(ele => ele === false)
              if (filterdRes.length > 0) {
                field["hide"] = false
                if (field["hide"] == false && field["required"] == false) {
                  field["required"] = false
                } else {
                  field["required"] = true
                }
              } else {
                field["hide"] = true
                field["required"] = false
              }
            } else if (ruleType == "AND") {
              let filterdRes = ruleResults.filter(ele => ele === true)
              if (filterdRes.length > 0) {
                field["hide"] = true
                field["required"] = false
              } else {
                field["hide"] = false
                if (field["hide"] == false && field["required"] == false) {
                  field["required"] = false
                } else {
                  field["required"] = true
                }
              }
            }
          }
        }

        if (field.enableReadOnlyRule && field.enableReadOnlyRule == true) {
          switch (field.readOnlyRule.cond) {
            case "RO":
              let filteredRolesReadOnly = invoiceData?.assignmentDetails.filter(
                ele => field.readOnlyRule.displayRoles.includes(ele.stageName)
              )
              if (filteredRolesReadOnly && filteredRolesReadOnly.length > 0) {
                field["type"] = field.readOnlyRule.fieldType
                if (
                  field["type"] != "view" &&
                  field.required == true &&
                  field["hide"] == false
                ) {
                  // validateFields.push(field.key)
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              }
          }
        }
        if (field.enableMandatoryRule && field.enableMandatoryRule == true) {
          switch (field.mandatoryRule.cond) {
            case "mandatory-roles":
              let filteredRolesReadOnly =
                this.props.invoiceHeaderData?.assignmentDetails.filter(ele =>
                  field.mandatoryRule.displayRoles.includes(ele.stageName)
                )
              if (filteredRolesReadOnly && filteredRolesReadOnly.length > 0) {
                // field['required'] = field.mandatoryRule.required;
                if (
                  field.mandatoryRule.required == true &&
                  field["hide"] == false
                ) {
                  // validateFields.push(field.key)
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              }
          }
        }
        if (field.displayRoles && field.displayRoles.length == 0) {
          field["hide"] = false
        } else if (field.displayRoles && field.displayRoles.length > 0) {
          let filteredRoles = invoiceData?.assignmentDetails.filter(ele =>
            field.displayRoles.includes(ele.stageName)
          )
          if (filteredRoles && filteredRoles.length > 0) {
            field["hide"] = false
            field["required"] = false
            if (field["hide"] == false && field.required == true) {
              // validateFields.push(field.key);
              field["required"] = true
            } else {
              field["required"] = false
            }
          } else {
            field["hide"] = true
            if (field["hide"] == false && field.required == true) {
              // validateFields.push(field.key)
              field["required"] = true
            } else {
              field["required"] = false
            }
          }
        }
      }

      if (field.regex && field.regex != "") {
        let reg = field.regex;
        if (field.regex == 'other') {
          reg = field?.otherRegex;
        }
        if (
          invoiceData[field.key] &&
          invoiceData[field.key] != undefined &&
          invoiceData[field.key] != null &&
          invoiceData[field.key] != ""
        ) {
          let regex = new RegExp(reg, "g")
          let result = regex.test(invoiceData[field.key])
          if (result == false) {

            validateFields.push(
              field.errorMessage
            )
          }
        }
      }

      if (field["required"] == true) {
        if (
          invoiceData[field.key] == undefined ||
          invoiceData[field.key] === null
        ) {
          validateFields.push(
            "Please fill the mandatory fields like " + field.label
          )
        } else {
          let value = invoiceData[field.key];
          value = value.toString().trim();
          if (value == undefined ||
            value == null || value == "") {
            validateFields.push(
              "Please fill the mandatory fields like " + field.label
            )
          }
          if (isNaN(invoiceData[field.key])) {
            value = value.trim();
            if (value == undefined ||
              value == null || value == "") {
              validateFields.push(
                "Please fill the mandatory fields like " + field.label
              )
            }
          }
          if (field['type'] == 'checkbox') {
            if (invoiceData[field.key] === false) {
              validateFields.push(
                "Please fill the mandatory fields like " + field.label
              )
            }
          }
        }
      }
    }
  })
  return validateFields
}

export function extractValues(data, reqArray, output) {
  for (const reqItem of reqArray) {
    const [property, outputKey] = reqItem.split(':');
    if (data && data !== undefined && data !== null) {
      if (data[outputKey]) {
        output[property] = data[outputKey];
      }
    }
  }

  for (const key in data) {
    if (typeof data[key] === 'object') {
      extractValues(data[key], reqArray, output);
    }
  }
}

export function recursiveFuncRespAppend(data, req) {
  const result = {};
  extractValues(data, req, result);
  return result;
}

export function returnRequestBodyForMicroflow(data, requestBodyConfig) {
  let reqBdy = {}
  for (let i = 0; i < requestBodyConfig.length; i++) {
    let req = requestBodyConfig[i];
    if (req.requestedBodyType != 'none') {
      let replacedDataNotNone = returnReplacedDataForMicroflow(data, req.requestBodyMapJson);
      reqBdy[req.requestedBodyKey] = replacedDataNotNone
    } else {
      let replacedData = returnReplacedDataForMicroflow(data, req.requestBodyMapJson);
      reqBdy = {
        ...reqBdy,
        ...replacedData
      }
    }
  }
  return reqBdy;
}

export function returnReplacedDataForMicroflow(data, requestBodyMapJson) {
  let reqBdy = {}
  for (let r = 0; r < requestBodyMapJson.length; r++) {
    if (requestBodyMapJson[r].responseKey && data && data[requestBodyMapJson[r].responseKey]) {
      reqBdy[requestBodyMapJson[r].updateKey] = data[requestBodyMapJson[r].responseKey]
    } else if (requestBodyMapJson[r].updateKey == 'NULL') {
      reqBdy[requestBodyMapJson[r].updateKey] = null
    } else if (requestBodyMapJson[r].updateKey == 'UNDEFINED') {
      reqBdy[requestBodyMapJson[r].updateKey] = undefined
    } else {
      reqBdy[requestBodyMapJson[r].updateKey] = requestBodyMapJson[r].responseKey
    }
  }
  return reqBdy;
}